h1 {
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.label {
  font-size: 1rem;
  color: $text;
  transition: $transition;
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-size: 1rem;
  font-weight: bold;
}

.text {
  &--grey {
    color: $text-lighter !important;
  }

  &--primary {
    color: $primary;
  }

  &--normal {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }

  &--warning {
    color: $red !important;
  }
}

.price {
  font-weight: bold;
  color: $primary-lighter;
  display: flex;
  justify-content: flex-start;

  .euro {
    margin-top: .1rem;
    font-size: .9rem;
  }
}

