.loading {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 999px;
  border: 3px solid $border;
  position: relative;
  animation: loading-animation 2s ease-in-out infinite;

  &:after {
    content: '';
    position: absolute;
    width: .5rem;
    height: .5rem;
    left: 50%;
    top: 0;
    background-color: $primary;
    border-radius: 999px;
    transform: translate(-50%, -50%);
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }
}

@keyframes loading-animation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
