.tooltip {
  position: fixed;
  background-color: $background-modal;
  border: 1px solid $border;
  padding: .25rem .5rem;
  white-space: nowrap;
  transform: translateX(-50%);
  font-size: .8rem;

  &--below {
    transform: translateX(-50%);
    margin-top: .5rem;
  }

  &--above {
    transform: translate(-50%, calc(-100% - .5rem));
  }

  &--left {
    transform: translate(calc(-100% - .5rem), -50%);
  }

  &--right {
    transform: translateY(-50%);
    margin-left: .5rem;
  }
}

@media (hover:none) {
  .tooltip {
    display: none;
  }
}
