$primary-lighter: #1C75BC;
$primary: #1b4074;
$primary-darker: #122e57;

$accent: #ffa826;

$text: #000000;
$text-lighter: #999999;

$grey-light: #F5F5F5;
$border: #DDDDDD;

$background: #F7F8FA;
$background-modal: #FFFFFF;

$red: #DC4444;
$green: #5ea459;
$orange: #e6810b;

$floor-dark: #999999;
$floor-light: #b3b3b3;
$wall: #f3f2ef;
