.startscreen {
  padding: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5rem;
  }

  &__title {
    color: $primary;
    line-height: 140%;
    margin-bottom: 1rem;
  }

  &__content {
    width: 100%;

    .video {
      aspect-ratio: 16 / 9;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: $default-shadow;
      }
    }
  }
}

@media (max-width: 600px) {
  .startscreen {

    &__header {
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
    }

    &__text {
      margin-bottom: 2rem;
    }
  }
}
