.translation-button {
  &__container {
    display: flex;
    flex-direction: column;
    border: 1px solid $border;
    background: $background-modal;
    padding: .25rem;
    margin-top: .5rem;

    button {
      height: 3rem;
      padding: 0 .5rem;
      text-align: left;
      transition: background-color .1s ease-in-out;
      background-color: $background-modal;


      &:hover {
        filter: brightness(.95);
      }

    }
  }
}
