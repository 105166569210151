$default-margin-bottom: 12rem;
$default-floor-height: 14rem;
$small-margin-bottom: 4rem;
$small-floor-height: 6rem;
$triangle-width: 6px;
$triangle-height: 5px;

.configurator {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;

  &--small-icons {
    .button {

      &--icon {
        padding: .4rem;

        .material-symbols-outlined {
          font-size: 1rem;
        }
      }

      &__info {
        right: -.5rem;
        top: -.5rem;
        font-size: .8rem;
      }
    }

    .configurator__product__edit-icons {
      top: .2rem;
      right: .2rem;
      gap: .2rem;
    }

    .configurator__product__accessory-icon {
      font-size: .8rem;
    }
  }

  &--mini-icons {
    .button--icon {
      padding: .2rem;

      .material-symbols-outlined {
        font-size: .8rem;
      }
    }
  }

  &__container {
    background-color: $wall;
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
  }

  &__screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__back-button {
    position: absolute;
    left: 1rem;
    top: 1rem
  }

  &__buttons {
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: flex;
    gap: .5rem;
    z-index: 1;

    .button {
      width: fit-content;
    }

    &--colors {
      display: flex;
      gap: .5rem;
    }
  }

  &__color-chooser {
    cursor: pointer;

    .color__container {
      height: calc(2.4rem + 2px);
      aspect-ratio: 1;
      background-color: $background-modal;
      padding: .2rem;
      border: 1px solid $border;
      transition: $transition;
      position: relative;

      > div {
        width: 100%;
        height: 100%;
      }

      .color__check {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        right: -.3rem;
        top: -.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        color: $background-modal;
        border-radius: 999px;
        opacity: 0;
        transition: $transition;

        span {
          font-size: .8rem;
        }
      }
    }

    input {
      display: none;

      &:checked + .color__container {
        border-color: $primary;

        .color__check {
          opacity: 1;
        }
      }
    }
  }

  &__add-button {
    padding: 0 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $default-margin-bottom;
    position: relative;

    .button {
      margin-bottom: 1rem;
      cursor: pointer;
    }

    .label, .button {
      opacity: 0;
      pointer-events: none;
    }

    &--visible {
      .label, .button {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &--no-padding {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    &--empty {
      align-self: stretch;
      flex: 1;
      padding: 0 !important;
      height: auto;
      margin: calc(2.9rem + 2px) 0 0;

      .button {
        margin: 0;
      }
    }

    &:hover {
      .label, .button {
        opacity: 1 !important;
        pointer-events: auto;
      }
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  &__hover-container {
    display: flex;
    height: 100%;
    align-items: flex-end;

    .button {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      .configurator__product__edit-icons .button,
      .bottom-stack__icons .button,
      .configurator__add-button .button {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &__measurement-holder {
    display: flex;

    .line {
      background: $primary;
      transition: $transition;
      position: relative;

      &:before {
        content: '';
        position: absolute;
      }

      &:after {
        content: '';
        position: absolute;

      }
    }

    p {
      color: $primary;
    }

    &--horizontal {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;

      p {
        margin-bottom: 1rem;
      }

      .line {
        height: 1px;

        &:before {
          left: 0;
          top: -$triangle-height;
          border-top: $triangle-height solid transparent;
          border-bottom: $triangle-height solid transparent;
          border-right: $triangle-width solid $primary
        }

        &:after {
          right: 0;
          top: -$triangle-height;
          border-top: $triangle-height solid transparent;
          border-bottom: $triangle-height solid transparent;
          border-left: $triangle-width solid $primary;
        }
      }
    }

    &--vertical {
      height: 100%;
      align-items: flex-end;
      padding-bottom: $default-margin-bottom;

      p {
        margin-right: 1rem;
      }

      .line {
        width: 1px;

        &__container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: .5rem;
          padding-right: .5rem;
          text-align: right;
        }

        &:before {
          top: 0;
          left: -$triangle-height;
          border-bottom: $triangle-width solid $primary;
          border-left: $triangle-height solid transparent;
          border-right: $triangle-height solid transparent;
        }

        &:after {
          bottom: 0;
          left: -$triangle-height;
          border-top: $triangle-width solid $primary;
          border-left: $triangle-height solid transparent;
          border-right: $triangle-height solid transparent;
        }
      }
    }

    &--light {
      .line {
        background: $background;

        &:before {
          border-right-color: $background;
        }

        &:after {
          border-left-color: $background;
        }
      }

      p {
        color: $background;
      }
    }
  }

  &__products {
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom: $default-margin-bottom;
  }

  &__product-stack {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &--bottom {
      margin-left: -1px;
    }
  }

  &__different-width {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  &__product {
    position: relative;

    &:after {
      content: '';
      background: rgba(0, 0, 0, .5);
      position: absolute;
      filter: blur(50px);
      width: 5rem;
      left: -2rem;
      top: 2rem;
      bottom: 0;
      z-index: -1;
    }

    .button {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;

      .configurator__product__accessory-icon {
        opacity: 1;
        pointer-events: initial;
      }
    }

    img {
      height: auto;
      width: auto;
      object-fit: cover;
      border-top: 1px solid;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $text-lighter;
    }

    &__edit-icons {
      position: absolute;
      top: .5rem;
      right: .5rem;
      display: flex;
      flex-direction: column;
      z-index: 1;
      gap: .5rem;
    }

    &__accessory-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &__move-icon {
      position: absolute;
      z-index: 3;
      bottom: -1rem;

      &--left {
        left: .5rem;
      }

      &--right {
        right: .5rem;
      }

      &--center {
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 14rem;
    background: linear-gradient(0, $floor-light, $floor-dark);

    &--img {
      overflow: hidden;

      div {
        transform: perspective(20rem) rotateX(30deg) scale(3);
        background-size: 5%;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }
  }

  &__wall {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--img {
      overflow: hidden;

      div {
        background-size: 5%;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }
  }
}

.bottom-stack {
  &__icons {
    position: absolute;
    z-index: 3;
    bottom: -1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;

    &--desktop {
      &-small {
        display: none !important;
      }
    }

    &--mobile {
      display: none;
      align-items: center;
      position: absolute;

      &-small {
        justify-content: center;
        display: flex !important;
      }
    }
  }


  &__more {
    position: absolute;
    bottom: calc(100% + 1rem);

    .button {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: -1px;
      }

      &:last-child:disabled {
        border-top: 1px solid $border;
      }
    }
  }
}

.room-placeholder {
  height: 7rem;
  width: 100%;
  border: 1px solid $text-lighter;

  &__container {
    height: 10rem;
    width: 100%;
    display: flex;

  }

  &__width {
    width: 100%;
  }
}

.line__warning {
  & > span {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .material-symbols-outlined {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {

  .configurator {
    width: initial;
    margin: 0 auto;

    &__add-button {
      padding: 0 1rem;
    }

    &__measurement-holder {
      &--vertical {
        padding: $small-margin-bottom 0;

        p {
          margin-bottom: 1rem;
          margin-right: 0;
        }

        .line__container {
          flex-direction: column;
        }
      }
    }

    &__container {
      overflow: auto;
    }

    &__hover-container {
      overflow: initial;
    }

    &__products {
      margin-bottom: $small-margin-bottom;
    }

    &__background {
      height: $small-floor-height;
    }
  }

  .bottom-stack__icons {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: flex;
      justify-content: center;
    }
  }
}

@media (hover: none) {
  .configurator {
    &__add-button {
      .button {
        opacity: 1;
      }
    }

    &__hover-container {
      .button {
        opacity: 1;
      }
    }
  }
}
