* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Inter', serif;
  font-size: 14px;
  background: $background;
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
