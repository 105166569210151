.modal {
  width: 60vw;
  max-width: 400px;
  height: fit-content;
  max-height: 100%;
  background-color: $background-modal;
  padding: 2rem;
  border: 1px solid $border;
  box-shadow: $default-shadow;
  overflow-y: auto;

  & > *:first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--scroll {
    height: 100%;

    .modal__content {
      overflow: auto;

      &-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }


  }

  &__wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.1);

  }

  &__header {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem;

    &-text {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }

  &__subtitle {
    color: $text-lighter
  }

  &--giant {
    width: 100%;
    max-width: 1000px;
    height: 100%;
  }

  &--wide {
    width: 100%;
    min-width: 400px;
    max-width: 800px;
  }
}

@media (max-width: 600px) {
  .modal {
    width: calc(100vw - 2rem);

    &--giant {
      width: 100%;
    }

    &--wide {
      min-width: initial;
    }

    &__wrapper {
      padding: .5rem;
    }
  }
}
