.card {
  padding: 1rem;
  background-color: $background-modal;
  height: 100%;
  max-height: 100%;
  outline: 1px solid transparent;
  transition: transform, border, outline-color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  border: 1px solid $border;
  text-align: left;

  &:hover {
    position: relative;
    z-index: 1;
    border: 1px solid $text-lighter;
  }

  &__title {
    color: $primary;
    font-size: 1rem;
  }

  &__subtitle {
    color: $text-lighter;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
  }

  &__content {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__variation-title {
    margin-bottom: .5rem;
    font-weight: bold;
  }

  &__product {
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      flex: 1;
      height: 100%;

      img {
        max-height: 200px;
      }
    }
  }
}
