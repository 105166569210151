input {
  border: 1px solid $border;
  transition: $transition;
  padding: .5rem;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:hover {
    border: 1px solid $text-lighter
  }

  &:focus-visible {
    border: 1px solid $text-lighter;
    outline: none;
  }
}

.option {
  &__info {
    margin-bottom: .5rem;
  }

  &__colors {
    display: flex;
    gap: .5rem;

    &-chooser {
      width: 100%;

      .color__container {
        width: 100%;
        height: 100%
      }
    }
  }

  &__title {
    font-weight: bold;
    color: $primary;

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__label {

    &--50 {
      width: 50%;
    }

    .label {
      &__container {
        height: 100%;
        max-height: 40vh;
        width: 100%;
        background-color: $background-modal;
        border: 1px solid $border;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem;
        cursor: pointer;
        transition: $transition;

        &:hover {
          position: relative;
          z-index: 1;
          border-color: $text-lighter;
        }

        .option--selected {
          display: none;
          color: $primary;
          cursor: pointer;
          font-size: 1.2rem;
        }

        .option--not-selected {
          color: $border;
          cursor: pointer;
          font-size: 1.2rem;
        }
      }

      &__img-container {
        flex: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        img {
          object-fit: contain;
        }
      }

      &__img {
        height: 5rem;
        margin-top: 1rem;
        object-fit: cover;
      }
    }

    input {
      display: none;

      &:checked + .label__container {
        border-left: 1px solid;
        border-color: $primary;
        position: relative;

        .option--not-selected {
          display: none;
        }

        .option--selected {
          display: block;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    .option__label {
      width: 50%;
    }
  }
}

.searchbar {
  width: 100%;
  padding: .5rem 1rem .5rem 3rem;
  height: 3rem;
  transition: $transition;
  background-color: $grey-light;
  border: 1px solid $border;

  &__container {
    position: relative;

  }

  &__icon {
    position: absolute;
    //left: 0;
    top: calc(.9rem);
    left: .9rem;
    font-size: 1.2rem;
    color: $text-lighter;
  }

  &:hover {
    //box-shadow: $default-shadow;
  }

  &:focus-visible {
    //box-shadow: $default-shadow;
    outline: $text-lighter;
  }
}

.input {
  &--number {
    padding: .5rem 0;
    width: calc(2.4rem + 2px);
    text-align: center;
  }
}

.error-message {
  color: $red;
  font-size: .8rem;
}
