$animationDuration: 20;

.usp {
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: $background;
    height: 100%;
  }

  &__information {
    display: flex;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;

    &__group {
      display: flex;
      gap: .5rem;
    }
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    font-size: .8rem;
    width: 100%;
    padding: .5rem;

    .material-symbols-outlined {
      font-size: 1rem;
    }
  }

  &__link {
    border-left: 1px solid $primary-lighter;
    padding-left: 1rem;
    text-decoration: underline;
    white-space: nowrap;

    a {
      cursor: pointer;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 5rem 1rem;
    max-width: 800px;
    margin: 0 auto;
  }

  &__gallery {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 100%;

    .row {
      display: flex;
      gap: .5rem;

      img {
        height: 100%;
        width: auto;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 800px) {
  .usp {
    &__container {

    }

    &__information {
      position: relative;
      height: 3rem;
      width: 100%;
      display: flex;
      gap: 2rem;
      max-width: 800px;
      margin: 0 auto;

      &__group {
        display: flex;
        gap: 2rem;
        position: absolute;
        width: 100%;
        height: 100%;
        animation: uspFadeHalf $animationDuration * 1s infinite;

        &:first-child {
          animation-delay: calc(-1s * $animationDuration / 2);
        }
      }
    }

    &__element {
      padding: 0.5rem 1rem;
    }
  }
}

@media (max-width: 600px) {
  .usp {
    &__information {
      &__group {
        animation: none;

        &:last-child {
          .usp__element {
            &:first-child {
              animation-delay: calc(-1s * $animationDuration / 2) !important;
            }

            &:nth-child(2) {
              animation-delay: calc(-1s * $animationDuration / 4);
            }
          }
        }
      }
    }
    &__element:not(.usp__link) {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: uspFadeAll infinite $animationDuration * 1s;

      &:first-child {
        animation-delay: calc(-1s * $animationDuration / 4 * 3);
      }
    }
  }
}

@keyframes uspFadeHalf {
  from {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes uspFadeAll {
  from {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}
