.button {
  background-color: $border;
  border: 1px solid $border;
  color: $text;
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  position: relative;

  &:hover {
    filter: brightness(.95);
  }

  &--text-icon {
    padding: .5rem .5rem .5rem 1rem;
    white-space: nowrap;

    .material-symbols-outlined {
      font-size: 1.2rem;
      margin-right: .5rem;
    }
  }

  &--icon-text {
    padding: .5rem 1rem .5rem .5rem;
    white-space: nowrap;

    .material-symbols-outlined {
      font-size: 1.2rem;
      margin-right: .5rem;
    }
  }

  &--icon-text-icon {
    padding: .5rem;
    white-space: nowrap;

    .material-symbols-outlined {
      font-size: 1.2rem;

      &:first-child {
        margin-right: .5rem;
      }

      &:last-child {
        margin-left: .5rem;
      }
    }
  }


  &--icon {
    padding: .6rem;

    .material-symbols-outlined {
      font-size: 1.2rem;
    }

    &--small {
      padding: .4rem;

      .material-symbols-outlined {
        font-size: 1rem;
      }
    }
  }

  &--round {
    border-radius: 100%;
  }

  &--primary {
    background-color: $primary;
    border-color: $primary;
    color: $background;
  }

  &--accent {
    background-color: $accent;
    border-color: $accent;
    color: $background;
  }

  &--outline {
    color: $text;
    background-color: $background-modal;
    border-color: $border;
  }

  &--close {
    color: $border;
    padding: .5rem;

    &:hover {
      color: $text-lighter;
      border-color: $text-lighter;
      filter: none;
    }
  }

  &--red {
    color: $red;
  }

  &--warn {
    background-color: $red;
    border-color: $red;
    color: $background;
  }

  &__info {
    background-color: $accent;
    color: $background;
    position: absolute;
    right: -.75rem;
    top: -.75rem;
    border-radius: 999px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $grey-light;
    border-color: $grey-light;
    color: $text-lighter;

    &:hover {
      filter: none;
    }
  }

}

.color-filter {
  position: relative;
  height: 100%;

  .black, .white {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: 1px solid $border;
    border-radius: 999px;
    width: 1rem;
    height: 1rem;

    div {
      width: 100%;
      height: 100%;
      border-radius: 999px;
    }
  }

  .black {
    div {
      background-color: #000;
    }
  }

  .white {
    div {
      background-color: #FFF;
    }
  }

  &__button {
    height: 100%;
    display: flex;
    gap: .5rem;
    width: 15rem;
    justify-content: space-between;

    .material-symbols-outlined {
      font-size: 1rem;
    }
  }

  &__text {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__dropdown {
    position: absolute;
    border: 1px solid $border;
    top: calc(100% + .5rem);
    right: 0;
    background-color: $background-modal;
    min-width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;

    input {
      display: none;
    }

    label {
      padding: .5rem;
      display: flex;
      gap: .5rem;
      align-items: center;
      width: 100%;
      cursor: pointer;
      background-color: $background-modal;

      &:hover {
        filter: brightness(.85);
      }

      .color {

        &__text {
          display: flex;
          align-items: center;
          gap: .5rem;
        }

        &__check {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary;
          border-radius: 999px;

          span {
            font-size: 1rem;
          }
        }
      }

    }
  }
}

@media (max-width: 600px) {
  .color-filter {
    &__button {
      width: initial;
    }
  }
}

@media (hover: none) {
  .button {
    &:hover {
      filter: initial;
    }
  }
}

