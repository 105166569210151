.list {
  overflow: auto;
  border: 1px solid $border;

  &__item {
    border-top: 1px solid $border;

    &:first-of-type {
      border-top: none;
    }

    &-container {
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: center;
      height: 12rem;
      padding: 1rem;
    }

    &-img {
      height: 100%;

      img {
        height: 100%;
        width: auto;
      }
    }

    &-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
    }

    &-info {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: .5rem;
      margin-bottom: .5rem;
    }

    &-buttons {
      display: flex;
      gap: .5rem;
      height: fit-content;
    }
  }
}
