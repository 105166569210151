.product {
  &__grid {
    display: grid;
    margin-bottom: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

    &-item {
      margin-top: -1px;
      margin-left: -1px;
    }

    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.products {
  &__reload-message {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .product {
    &__grid {
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    }
  }
}
