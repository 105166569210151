.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.icon {
  &--small {
    font-size: 1.2rem;
    height: fit-content;
  }
}
