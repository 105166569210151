$border-width: 2px;

.pop-up {
  padding: 2rem;
  width: 30rem;
  box-shadow: $default-shadow;
  border: 1px solid $border;
  background-color: $background-modal;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  transition: $transition;
  animation: slideIn $transition-duration ease-in-out;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $border-width;
    animation: timer 5s ease-in-out;
    transform-origin: left;
    background-color: $text-lighter;
  }

  .button {
    align-self: flex-start;
    border: none;
    padding: 0;
  }

  &__container {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
  }

  &__title {
    font-size: 1rem;
    margin-bottom: .5rem;
  }

  &__text {
    color: $text-lighter;
  }

  &--message {
  }

  &--error {
    .pop-up__title {
      color: $red;
    }

    &:before {
      background-color: $red;
    }
  }

  &--success {
    .pop-up__title {
      color: $green;
    }

    &:before {
      background-color: $green;
    }
  }

  &--warning {
    .pop-up__title {
      color: $orange;
    }

    &:before {
      background-color: $orange;
    }
  }

  &[aria-expanded='false'] {
    transform: translateX(-100%);
  }

}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes timer {
  from {
    transform: scaleX(100%);
  }

  to {
    transform: scaleX(0);

  }
}
