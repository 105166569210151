.category {
  &__container {
    cursor: pointer;
    display: flex;
    gap: .5rem;
    padding: .5rem 1rem;
    border-bottom: 1px solid $border;

    &--selected {
      border-bottom: .2rem $primary solid;
      font-weight: bold;
    }
  }

  &__list {
    display: flex;
    row-gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__number {
    font-size: .75rem;
    color: $text-lighter;
    background-color: $grey-light;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25rem .5rem;
  }
}
